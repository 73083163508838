<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'KeywordList',
              }"
              >{{ "關鍵字列表" }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly ? "查看關鍵字" : isEditing ? "編輯關鍵字" : "新增關鍵字"
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly ? "查看關鍵字" : isEditing ? "編輯關鍵字" : "新增關鍵字"
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*關鍵字"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.keyword.keyword.$error ? false : null"
                    v-model="keyword.keyword"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.keyword.keyword.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="*對應程式代號"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.keyword.event.$error ? false : null"
                    v-model="keyword.event"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.keyword.event.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>


                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="是否啟用"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="keyword.enabled"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="一次性回覆"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="keyword.is_once"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="是否正規表示"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="keyword.is_regex"
                    :disabled="isReadOnly"
                  ></b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import keywordApi from "../../../apis/keyword";

export default {
  setup: () => ({ v$: useVuelidate() }),

  validations() {
    return {
      keyword: {
        keyword: { required },
        event: { required },
      }
    };
  },

  data() {
    return {
      showLoading: false,
      isEditing: true,
      validationErrors: null,
      keyword: {
        keyword: null,
        event: null,
        enabled: true,
        is_once: false,
        is_regex: false,
      },
      file: null,
      showLoadingUpload: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    isReadOnly() {
      return this.$route.name === "KeywordView";
    },
  },
  mounted() {
    if (!this.$route.params.keyword_id) {
      this.clearKeyword();
      this.isEditing = false;
    } else {
      this.fetchKeyword();
      this.isEditing = true;
    }
  },
  methods: {
    async fetchKeyword() {
      this.showLoading = true;
      const response = await keywordApi.getKeyword(
        this.organization,
        this.$route.params.keyword_id
      );
      this.keyword = response.data.data;
      this.showLoading = false;
    },

    clearKeyword() {
      this.keyword = {
        keyword: null,
        event: null,
        enabled: true,
        is_once: false,
        is_regex: false,
      };
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      try {
        if (this.isEditing) {
          let response = await keywordApi.updateKeyword(
            this.organization,
            this.keyword
          );
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });

            this.$router.push({ name: "KeywordList" });
          }
        } else {
          let response = await keywordApi.storeKeyword(this.keyword);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.$router.push({ name: "KeywordList" });
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    handleCancel() {
      this.$router.push({ name: "KeywordList" });
    }
  },
};
</script>
