import https from "./https"
import store from "@/store"

const keywordApi = {
  getKeywords (organization, params) {
    return https.get(`admin/organizations/${organization.id}/keywords`, { params })
  },
  getKeyword (organization, keywordId) {
    return https.get(`admin/organizations/${organization.id}/keywords/${keywordId}`)
  },
  updateKeyword (organization, params) {
    return https.put(`admin/organizations/${organization.id}/keywords/${params.id}`, params)
  },
  removeKeyword (organization, params) {
    const url = 'admin/organizations/' + organization.id + '/keywords/' + params.id;
    return https.delete(url)
  },
  storeKeyword (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/keywords'

    return https.post(url, params)
  }
}

export default keywordApi
